import { nanoid } from 'nanoid'
import type { FetchOptions } from 'ofetch'
import type { $Fetch, NitroFetchRequest } from 'nitropack'
import { AuthRedirectCondition } from '@authentication'

export default defineNuxtPlugin({
  name: 'api',
  dependsOn: ['newrelic-browser'],
  setup() {
    const { apiURL, managerURL, auditURL } = useRuntimeConfig().public
    const route = useRoute()
    const { status, signIn } = useAuth()
    const { setRedirect } = useAuthRedirect()
    const newRelic = useNewRelic()

    const initHeaders = (options: FetchOptions) => {
      const headers = (options.headers ||= {})
      const setHeader = (name: string, value: string) => {
        if (Array.isArray(headers)) {
          headers.push([name, value])
        } else if (headers instanceof Headers) {
          headers.set(name, value)
        } else {
          headers[name] = value
        }
      }

      return { setHeader }
    }

    const api = $fetch.create({
      baseURL: apiURL,
      credentials: 'include',
      onRequest: ({ options }) => {
        const { setHeader } = initHeaders(options)
        setHeader('Correlation-id', nanoid())
      },
      onResponseError: async (ctx) => {
        if (ctx.error) newRelic.setError(ctx.error)

        if (status.value === 'authenticated' && ctx.response.status === 401) {
          // remember destination and redirect to login
          setRedirect({
            url: route.fullPath,
            condition: AuthRedirectCondition.Authenticated,
          })

          await signIn()
          return
        }

        throw createApiError(ctx)
      },
      retry: false, // vue-query handles the retry
    })

    const apiManager = $fetch.create({
      baseURL: managerURL,
      credentials: 'include',
      onRequest: ({ options }) => {
        const { setHeader } = initHeaders(options)
        setHeader('Correlation-id', nanoid())
      },
      onResponseError: async (ctx) => {
        if (ctx.error) newRelic.setError(ctx.error)

        if (status.value === 'authenticated' && ctx.response.status === 401) {
          // remember destination and redirect to login
          setRedirect({
            url: route.fullPath,
            condition: AuthRedirectCondition.Authenticated,
          })

          await signIn()
          return
        }

        throw createApiError(ctx)
      },
      retry: false, // vue-query handles the retry
    })

    const apiAudit = $fetch.create({
      baseURL: auditURL,
      credentials: 'include',
      onRequest: ({ options }) => {
        const { setHeader } = initHeaders(options)
        setHeader('Correlation-id', nanoid())
      },
      onResponseError: async (ctx) => {
        if (ctx.error) newRelic.setError(ctx.error)
        if (status.value === 'authenticated' && ctx.response.status === 401) {
          // remember destination and redirect to login
          setRedirect({
            url: route.fullPath,
            condition: AuthRedirectCondition.Authenticated,
          })
          await signIn()
          return
        }
        throw createApiError(ctx)
      },
      retry: false,
    })

    // Expose to useNuxtApp().$api
    return {
      provide: {
        api,
        apiManager,
        apiAudit,
      },
    }
  },
})

declare module '#app' {
  interface NuxtApp {
    $api: $Fetch
    $apiManager: $Fetch
    $apiAudit: $Fetch
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $api: $Fetch
    $apiManager: $Fetch
    $apiAudit: $Fetch
  }
}
